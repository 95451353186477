<template>
  <section class="Legal">
    <article>
      <div>
        <h3>&copy;</h3>
        <p class="copyright">Copyright &copy; 2021 Kelly Milligan.</p>
        <p>All Rights Reserved.</p>
        <p>Images may not be used without the express written permission of the artist.</p>
      </div>
      <div>
        <h3>Privacy</h3>
        <p>This website does not attempt to collect your data or track your actions.</p>
        <p>It's hosted on <a href="https://www.netlify.com/" target="_blank">Netlify</a>, which have their own policies around server-side data collection.</p>
      </div>
      <div>
        <p>Enquiries: <a href="mailto:kellymilligan.art@gmail.com" target="_blank">kellymilligan.art@gmail.com</a></p>
      </div>
    </article>
  </section>
</template>

<script>

export default {
  name: 'Legal',
  props: {},
  components: {},
  data() {
    return {

    }
  },
  mounted() {

  },
  beforeDestroy() {
  },
  methods: {

  }
}

</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  .Legal {

    position: relative;

    article {

      padding: rem( 160 ) 16vw 0 15vw;

      @include breakpoint( mobile ) {

        padding: rem( 20 ) rem( 25 ) 0 rem( 25 );

      }

      > div {

        margin: rem( 80 ) 0;

      }

      h3 {

        margin: rem( 24 ) 0;

        @include fontBlack();
        font-size: rem( 60 );
        line-height: 0.87;

        @include breakpoint( mobile ) {

          font-size: rem( 36 );

        }

      }

      p {

        margin: rem( 2 ) 0;

        color: rgba( $color-base, 0.45 );

        a {

          @include fontMedium();
          text-decoration: none;

          transition: color 400ms $ease-out-quart;

          &:hover {

            color: rgba( $color-base, 0.75 );

          }

        }

      }

    }

  }

</style>
